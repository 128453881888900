<template>
  <div>
    <div class="experience">
      <div class="experience-main">
        <div class="experience-main-left">
          <div class="experience-main-left-head">预约成功后，您将获得</div>
          <div class="experience-main-left-list">
            <div
              class="list-item"
              v-for="(item, index) of listData"
              :key="index"
            >
              <img
                src="../../images/newHome/reservation/icon.png"
                alt=""
                class="icon"
              />
              {{ item }}
            </div>
          </div>
          <div class="experience-main-left-scroll">
            <div class="scroll-head">
              <div class="scroll-title">
                <div class="experience-main-left-scroll-title">
                  11万合规人士的共同选择
                </div>
                <div class="experience-main-left-scroll-desc">
                  已为 <span class="num">4000+</span>企业提供专业服务
                </div>
              </div>
              <img
                src="../../images/newHome/reservation/scroll-icon.png"
                alt=""
                class="scroll-icon"
              />
            </div>
            <div class="scroll-container">
              <div class="scroll-container-wrap">
                <div class="scroll-item">
                  <div class="xscroll xscroll40">
                    <img
                      class="scroll-img"
                      :src="item"
                      alt=""
                      v-for="(item, index) in imgList"
                      :key="index"
                    />
                  </div>
                  <div class="xscroll xscroll40">
                    <img
                      class="scroll-img"
                      :src="item"
                      alt=""
                      v-for="(item, index) in imgList"
                      :key="index"
                    />
                  </div>
                </div>
                <div class="scroll-item">
                  <div class="xscroll xscroll35">
                    <img
                      class="scroll-img"
                      :src="item"
                      alt=""
                      v-for="(item, index) in imgList2"
                      :key="index"
                    />
                  </div>
                  <div class="xscroll xscroll35">
                    <img
                      class="scroll-img"
                      :src="item"
                      alt=""
                      v-for="(item, index) in imgList2"
                      :key="index"
                    />
                  </div>
                </div>
                <div class="scroll-item">
                  <div class="xscroll xscroll25">
                    <img
                      class="scroll-img"
                      :src="item"
                      alt=""
                      v-for="(item, index) in imgList3"
                      :key="index"
                    />
                  </div>
                  <div class="xscroll xscroll25">
                    <img
                      class="scroll-img"
                      :src="item"
                      alt=""
                      v-for="(item, index) in imgList3"
                      :key="index"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="experience-main-right">
          <div class="experience-main-right-head">
            <div class="experience-main-right-head-title">预约产品演示</div>
            <div class="experience-main-right-head-desc">
              提交信息后，专属合规解决方案专家将在1日内与您取得联系
            </div>
          </div>
          <div class="experience-main-cont-form" v-loading="loading">
            <div class="experience-main-cont-l">
              <el-form
                ref="formRef"
                label-width="0"
                label-position="top"
                :model="ruleForm"
                :rules="rules"
              >
                <el-form-item :style="`width: ${formWidth}px`" prop="name">
                  <el-input
                    v-model="ruleForm.name"
                    placeholder="姓名（必填）"
                    clearable
                  />
                </el-form-item>
                <el-form-item :style="`width: ${formWidth}px`" prop="position">
                  <el-input
                    v-model="ruleForm.position"
                    placeholder="职务"
                    clearable
                  />
                </el-form-item>
                <el-form-item :style="`width: ${formWidth}px`" prop="phoneNum">
                  <el-input
                    v-model="ruleForm.phoneNum"
                    placeholder="手机号码（必填）"
                    clearable
                  />
                </el-form-item>
                <el-form-item :style="`width: ${formWidth}px`" prop="email">
                  <el-input
                    v-model="ruleForm.email"
                    placeholder="邮箱（必填）"
                    clearable
                  />
                </el-form-item>
                <el-form-item
                  :style="`width: ${formWidth}px`"
                  prop="companyName"
                >
                  <el-input
                    v-model="ruleForm.companyName"
                    placeholder="企业名称（必填）"
                    clearable
                  />
                </el-form-item>
                <el-form-item class="agree-checkbox" prop="agreeBox">
                  <el-checkbox v-model="ruleForm.agreeBox"></el-checkbox>
                  我已阅读并同意<span @click="openPrivacy">《隐私政策》</span>
                </el-form-item>
              </el-form>
              <div class="submit-btn" @click="submitDebounce">
                提交
                <img
                  src="../../images/newHome/reservation/arrow-right.png"
                  alt=""
                  class="arrow"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footerCom :isForm="true"></footerCom>
  </div>
</template>

<style lang="scss" scoped>
@import "./index.scss";
</style>

<script>
import footerCom from "../../components/footerCom/index.vue";
import api from "../../api/request";
export default {
  name: "index",
  components: { footerCom },
  data() {
    const validateEmail = (_rule, value, callback) => {
      let t = /^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,6}$/;
      if (value === "") {
        callback(new Error("请输入企业邮箱"));
      } else if (!t.test(value)) {
        callback(new Error("请输入正确格式的邮箱地址"));
      } else {
        callback();
      }
    };
    const validatePhone = (_rule, value, callback) => {
      let t = /^1[3456789]\d{9}$/;
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (!t.test(value)) {
        callback(new Error("请输入正确格式的手机号"));
      } else {
        callback();
      }
    };
    const validateAgree = (_rule, value, callback) => {
      if (!value) {
        callback(new Error("请勾选阅读并同意隐私政策"));
      } else {
        callback();
      }
    };
    const zte = require("../../images/newHome/zte.png");
    const zl = require("../../images/newHome/zl.png");
    const zjfd = require("../../images/newHome/zjfd.png");
    const ybx = require("../../images/newHome/ybx.png");
    const xyd = require("../../images/newHome/xyd.png");
    const tx = require("../../images/newHome/tx.png");
    const ts = require("../../images/newHome/ts.png");
    const tl = require("../../images/newHome/tl.png");
    const sqhy = require("../../images/newHome/sqhy.png");
    const qw = require("../../images/newHome/qw.png");
    const msxf = require("../../images/newHome/msxf.png");
    const mindray = require("../../images/newHome/mindray.png");
    const lz = require("../../images/newHome/lz.png");
    const joyy = require("../../images/newHome/joyy.png");
    const hw = require("../../images/newHome/hw.png");
    const gtzm = require("../../images/newHome/gtzm.png");
    const fc = require("../../images/newHome/fc.png");
    const dfhy = require("../../images/newHome/dfhy.png");
    const chnt = require("../../images/newHome/chnt.png");
    const bk = require("../../images/newHome/bk.png");
    const bjdh = require("../../images/newHome/bjdh.png");
    const bc = require("../../images/newHome/bc.png");
    const jyzy = require("../../images/newHome/jyzy.png");
    const albb = require("../../images/newHome/albb.png");

    const dljt = require("../../images/newHome/newLogo/dljt.png");
    const hnyc = require("../../images/newHome/newLogo/hnyc.png");
    const hrzc = require("../../images/newHome/newLogo/hrzc.png");
    const jd = require("../../images/newHome/newLogo/jd.png");
    const jyjt = require("../../images/newHome/newLogo/jyjt.png");
    const phyd = require("../../images/newHome/newLogo/phyd.png");
    const qddt = require("../../images/newHome/newLogo/qddt.png");
    const rczg = require("../../images/newHome/newLogo/rczg.png");
    const tb = require("../../images/newHome/newLogo/tb.png");
    const ydzn = require("../../images/newHome/newLogo/ydzn.png");
    const yjpz = require("../../images/newHome/newLogo/yjpz.png");
    const yjsgs = require("../../images/newHome/newLogo/yjsgs.png");
    const zjbj = require("../../images/newHome/newLogo/zjbj.png");
    const zyby = require("../../images/newHome/newLogo/zyby.png");
    return {
      rules: {
        name: [
          { required: true, message: "请输入您的姓名", trigger: "change" },
        ],
        phoneNum: [
          { required: true, trigger: "change", validator: validatePhone },
        ],
        email: [
          { required: true, trigger: "change", validator: validateEmail },
        ],
        companyName: [
          { required: true, message: "请输入企业名称", trigger: "change" },
        ],
        agreeBox: [{ required: true, validator: validateAgree }],
      },
      ruleForm: {
        name: "",
        phoneNum: "",
        email: "",
        companyName: "",
        position: "",
        agreeBox: false,
      },
      formWidth: 460,
      loading: false,
      listData: [
        "专业合规专家1V1交流",
        "企业专属个性化解决方案",
        "一体化法律合规解决方案",
        "高效便捷的智能办公体验",
        "免费体验系统功能",
        "专业团队答疑解惑",
      ],
      imgList: [zte, zl, zjfd, ybx, xyd, tx, ts, tl, dljt, hnyc, hrzc, jd],
      imgList2: [
        sqhy,
        qw,
        msxf,
        mindray,
        lz,
        joyy,
        hw,
        jyzy,
        jyjt,
        phyd,
        qddt,
        rczg,
        tb,
      ],
      imgList3: [
        gtzm,
        fc,
        dfhy,
        chnt,
        bk,
        bjdh,
        bc,
        albb,
        ydzn,
        yjpz,
        yjsgs,
        zjbj,
        zyby,
      ],
    };
  },
  methods: {
    async submit() {
      await this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          const {
            name,
            phoneNum,
            email,
            companyName,
            position,
          } = this.ruleForm;
          const params = {
            name,
            phoneNum,
            email,
            companyName,
            position,
          };
          const res = await api.addClues(params);
          if (res.code === 0) {
            this.$message.success("预约成功");
            this.$refs.formRef.resetFields();
          } else {
            this.$message.error(res.msg);
          }
          this.loading = false;
        } else {
          if (!this.ruleForm.agreeBox) {
            this.$message.error("请先同意协议内容");
          }
        }
      });
    },
    openPrivacy() {
      window.open(`${window.location.origin}/document-privacy`, "_blank");
    },
    _debounce(func, time) {
      let timer;
      return function(...args) {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
          //  @ts-ignore
          func.apply(this, args);
        }, time);
      };
    },
    submitDebounce() {
      this._debounce(this.submit(), 500);
    },
  },
};
</script>
